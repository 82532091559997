import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '97%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
    },
    palette: {
      mode: 'light',
      action: {
        active: '#6b778c',
      },
      background: {
        default: '#f9fafb',
        paper: '#ffffff',
      },
      primary: {
        contrastText: '#ffffff',
        main: '#f15a1a',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      error: {
        contrastText: '#ffffff',
        main: '#ff0033',
      },
      text: {
        primary: '#172b4d',
        secondary: '#000000',
        black: '#ffffff',
        contrast: '#f15a1a',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
      softwareVersionText: {
        main: '#f15a1a',
        contrastText: '#7e96df;',
      },
      navBarButtons: {
        main: '#f15a1a',
        contrastText: '#f15a1a;',
      },
      productDefective: {
        main: '#D84435',
        contrastText: '#ffffff',
      },
      productHasIssue: {
        main: '#FCB939',
        contrastText: '#ffffff',
      },
      batchProductEditBtn: {
        main: '#FFFFFF',
        contrastText: '#7e96df;',
      },
      containerCreated: {
        main: '#69d6c5',
        contrastText: '#ffffff',
      },
      containerInProgress: {
        main: '#4c99f7',
        contrastText: '#ffffff',
      },
      containerInTransit: {
        main: '#6fc499',
        contrastText: '#ffffff',
      },
      containerAtDestination: {
        main: '#86EA5C',
        contrastText: '#ffffff',
      },
      containerOnSite: {
        main: '#9452C8',
        contrastText: '#ffffff',
      },
      batchOrdered: {
        main: '#69d6c5',
        contrastText: '#ffffff',
      },
      batchShipped: {
        main: '#6fc499',
        contrastText: '#ffffff',
      },
      batchDelivered: {
        main: '#86EA5C',
        contrastText: '#ffffff',
      },
      batchActive: {
        main: '#4c99f7',
        contrastText: '#ffffff',
      },
      batchDefective: {
        main: '#d84435',
        contrastText: '#ffffff',
      },
      batchExpired: {
        main: '#FF9100',
        contrastText: '#ffffff',
      },
      batchFinished: {
        main: '#9452C8',
        contrastText: '#ffffff',
      },
      crateCreated: {
        main: '#69d6c5',
        contrastText: '#ffffff',
      },
      crateInProgress: {
        main: '#4c99f7',
        contrastText: '#ffffff',
      },
      crateFinalized: {
        main: '#6fc499',
        contrastText: '#ffffff',
      },
      crateInStorage: {
        main: '#86EA5C',
        contrastText: '#ffffff',
      },
      crateInTransitToSite: {
        main: '#E4BB97',
        contrastText: '#ffffff',
      },
      crateDeliveredToSite: {
        main: '#9452C8',
        contrastText: '#ffffff',
      },
      coSent: {
        main: '#2d99ff',
        contrastText: '#ffffff',
      },
      coApproved: {
        main: '#54d62c',
        contrastText: '#ffffff',
      },
      coRejeted: {
        main: '#826af9',
        contrastText: '#ffffff',
      },
      rcoSent: {
        main: '#2d99ff',
        contrastText: '#ffffff',
      },
      rcoApproved: {
        main: '#54d62c',
        contrastText: '#ffffff',
      },
      rcoRejeted: {
        main: '#826af9',
        contrastText: '#ffffff',
      },
      rcoVoid: {
        main: '#ff4842',
        contrastText: '#ffffff',
      },
      rfiOpen: {
        main: '#2d99ff',
        contrastText: '#ffffff',
      },
      rfiClosed: {
        main: '#54d62c',
        contrastText: '#ffffff',
      },
      chartStatusCreated: {
        main: '#455A64',
      },
      chartStatusInFab: {
        main: '#53c6cf',
      },
      chartStatusAssembled: {
        main: '#319ecf',
      },
      chartStatusQR: {
        main: '#545aa8',
      },
      chartStatusPostFab: {
        main: '#517bf4',
      },
      chartStatusGlazed: {
        main: '#0044b2',
      },
      chartStatusShipped: {
        main: '#B2D4AA',
      },
      chartStatusInStorage: {
        main: '#4B644A',
      },
      chartStatusOnSite: {
        main: '#C3EAB9',
      },
      chartStatusInstalled: {
        // main: '#049d75',
        main: '#86ea5c',
      },
      chartStatusSignedOff: {
        main: '#64e3b6',
      },
      chartStatusDefective: {
        main: '#d84435',
      },
      chartStatusHasIssue: {
        main: '#fcb939',
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      mode: 'dark',
      divider: 'rgba(145, 158, 171, 0.24)',
      background: {
        default: '#161c24',
        paper: '#212b36',
      },
      primary: {
        contrastText: '#ffffff',
        main: '#f15a1a',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      error: {
        contrastText: '#ffffff',
        main: '#ff0033',
      },
      textError: '#ff0033',
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
        error: '#ff0033',
        warning: '#ff0033',
        black: '#ffffff',
        contrast: '#f15a1a',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
      softwareVersionText: {
        main: '#f15a1a',
        contrastText: '#7e96df;',
      },
      navBarButtons: {
        main: '#f15a1a',
        contrastText: '#f15a1a;',
      },
      productDefective: {
        main: '#D84435',
        contrastText: '#ffffff',
      },
      productHasIssue: {
        main: '#FCB939',
        contrastText: '#ffffff',
      },
      batchProductEditBtn: {
        main: '#FFFFFF',
        contrastText: '#7e96df;',
      },
      containerCreated: {
        main: '#69d6c5',
        contrastText: '#ffffff',
      },
      containerInProgress: {
        main: '#4c99f7',
        contrastText: '#ffffff',
      },
      containerInTransit: {
        main: '#6fc499',
        contrastText: '#ffffff',
      },
      containerAtDestination: {
        main: '#86EA5C',
        contrastText: '#ffffff',
      },
      containerOnSite: {
        main: '#9452C8',
        contrastText: '#ffffff',
      },
      batchOrdered: {
        main: '#69d6c5',
        contrastText: '#ffffff',
      },
      batchShipped: {
        main: '#6fc499',
        contrastText: '#ffffff',
      },
      batchDelivered: {
        main: '#86EA5C',
        contrastText: '#ffffff',
      },
      batchActive: {
        main: '#4c99f7',
        contrastText: '#ffffff',
      },
      batchDefective: {
        main: '#d84435',
        contrastText: '#ffffff',
      },
      batchExpired: {
        main: '#FF9100',
        contrastText: '#ffffff',
      },
      batchFinished: {
        main: '#9452C8',
        contrastText: '#ffffff',
      },
      crateCreated: {
        main: '#69d6c5',
        contrastText: '#ffffff',
      },
      crateInProgress: {
        main: '#4c99f7',
        contrastText: '#ffffff',
      },
      crateFinalized: {
        main: '#6fc499',
        contrastText: '#ffffff',
      },
      crateInStorage: {
        main: '#86EA5C',
        contrastText: '#ffffff',
      },
      crateInTransitToSite: {
        main: '#E4BB97',
        contrastText: '#ffffff',
      },
      crateDeliveredToSite: {
        main: '#9452C8',
        contrastText: '#ffffff',
      },
      coSent: {
        main: '#2d99ff',
        contrastText: '#ffffff',
      },
      coApproved: {
        main: '#54d62c',
        contrastText: '#ffffff',
      },
      coRejeted: {
        main: '#826af9',
        contrastText: '#ffffff',
      },
      rcoSent: {
        main: '#2d99ff',
        contrastText: '#ffffff',
      },
      rcoApproved: {
        main: '#54d62c',
        contrastText: '#ffffff',
      },
      rcoRejeted: {
        main: '#826af9',
        contrastText: '#ffffff',
      },
      rcoVoid: {
        main: '#ff4842',
        contrastText: '#ffffff',
      },
      rfiOpen: {
        main: '#2d99ff',
        contrastText: '#ffffff',
      },
      rfiClosed: {
        main: '#54d62c',
        contrastText: '#ffffff',
      },
      chartStatusCreated: {
        main: '#455A64',
      },
      chartStatusInFab: {
        main: '#53C6CF',
      },
      chartStatusAssembled: {
        main: '#319ECF',
      },
      chartStatusQR: {
        main: '#545AA8',
      },
      chartStatusPostFab: {
        main: '#517BF4',
      },
      chartStatusGlazed: {
        main: '#0044B2',
      },
      chartStatusShipped: {
        main: '#B2D4AA',
      },
      chartStatusInStorage: {
        main: '#4B644A',
      },
      chartStatusOnSite: {
        main: '#C3EAB9',
      },
      chartStatusInstalled: {
        // main: '#049d75',
        main: '#86EA5C',
      },
      chartStatusSignedOff: {
        main: '#64E3B6',
      },
      chartStatusDefective: {
        main: '#D84435',
      },
      chartStatusHasIssue: {
        main: '#FCB939',
      },
    },
    shadows: darkShadows,
  },
  // [THEMES.NATURE]: {
  //   components: {
  //     MuiTableCell: {
  //       styleOverrides: {
  //         root: {
  //           borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
  //         }
  //       }
  //     }
  //   },
  //   palette: {
  //     background: {
  //       default: '#1c2531',
  //       paper: '#293142'
  //     },
  //     divider: 'rgba(145, 158, 171, 0.24)',
  //     error: {
  //       contrastText: '#ffffff',
  //       main: '#f44336'
  //     },
  //     mode: 'dark',
  //     primary: {
  //       contrastText: '#ffffff',
  //       main: '#01ab56'
  //     },
  //     success: {
  //       contrastText: '#ffffff',
  //       main: '#4caf50'
  //     },
  //     text: {
  //       primary: '#ffffff',
  //       secondary: '#919eab'
  //     },
  //     warning: {
  //       contrastText: '#ffffff',
  //       main: '#ff9800'
  //     }
  //   },
  //   shadows: darkShadows
  // }
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.DARK];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
