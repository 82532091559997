/**
 *@changelog
 * 2021-09-1 (Andy Luo) Created store to house Redux states
 * 2021-09-2 (Andy Luo) Integrated Redux-Persist Library to maintain userData
 * 2021-09-17 (Igor C) configured redux tools to be used only on dev environment
 */

import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
import rootReducer from './rootReducer';

const initialState = {};

const middleWare = [thunk];

export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();
export const store = createStore(
  rootReducer,
  initialState,
  process.env.NODE_ENV !== 'development'
    ? compose(applyMiddleware(...middleWare))
    : composeWithDevTools(applyMiddleware(...middleWare)),
);
export const persistor = persistStore(store);
export default { store, persistor };
